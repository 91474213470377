<template>
    <div id="main">
        <div class="text-center">
            <v-overlay style='z-index:20001;'>
                <v-card :color="color" class="mx-4">
                    <v-card-text class="text-center pt-8 ">
                        <v-progress-circular :size="70" :width="10" color="white" indeterminate />
                    </v-card-text>
                    <v-card-text class="headline white--text" style="min-width: 50vw" v-html="message" />
                    <v-card-text class="headline white--text" style="min-width: 50vw" v-if="progress">
                        <v-progress-linear :value="progress" color="white" :height="20" />
                        {{ timeout }} Segundos
                    </v-card-text>
                </v-card>
            </v-overlay>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        if (this.$route.params.charger && this.$route.params.plug && this.$route.params.user && this.$route.params.price && this.$route.params.plataform) {
            this.charger = this.$route.params.charger;
            this.plug = this.$route.params.plug;
            this.user = this.$route.params.user;
            this.price = this.$route.params.price;
            this.plataform = this.$route.params.plataform;

            if (this.plataform == "stripe" && this.$route.query && this.$route.query.payment_intent) {
                this.payment = this.$route.query.payment_intent;
            } else if (this.plataform == "mp" && this.$route.query && this.$route.query.payment_id) {
                this.payment = this.$route.query.payment_id;
            }
            if (this.payment != null && this.payment != "null") {
                this.check();
            } else {
                this.message = "Pagamento não localizado, tente novamente.";
                this.goToDetails();
            }
        }
        setInterval(() => {
            if (this.timeout > 0) {
                this.timeout--;
            }
        }, 1000);
    },

    data() {
        return {
            message: "Processando pagamento...",
            color: "success",
            charger: null,
            plug: null,
            user: null,
            price: null,
            plataform: null,
            payment: null,
            progress: undefined,
            timeout: undefined,
            counterError: 0,
            waitPlug: false
        };
    },

    methods: {
        goToDetails() {
            setTimeout(() => {
                this.$router.replace({ path: `/now/${this.charger}/${this.plug}` });
            }, 5000);
        },
        retryCheck() {
            setTimeout(() => {
                this.check();
            }, 3000);
        },
        check() {
            this.$http
                .postAlternativeAPI(`api/v2/payment/check`, { plataform: this.plataform, charger: this.charger, plug: this.plug, user: this.user, price: this.price, payment: this.payment })
                .then((result) => {
                    if (result.payment_status == "PROCCESS_ERROR") {
                        this.color = "warning";
                    } else {
                        this.color = "success";
                    }

                    if (result.payment_status == "WAIT") {
                        this.message = "Pagamento em processamento... <br><br>Aguarde...";
                        this.retryCheck();
                    } else if (result.payment_status == "PROCCESS_ERROR") {
                        this.message = "Erro ao processar o pagamento, tente outro metodo de pagamento.";
                        this.goToDetails();
                    } else if (result.payment_status == "PROCCESS_OK") {
                        this.message = "Pagamento feito com sucesso.";
                        this.goToDetails();
                    } else if (result.payment_status == "WAIT_START") {
                        this.message = "Pagamento feito com sucesso. <br>Tentando liberar o carregamento, aguarde...";
                        this.retryCheck();
                    } else if (result.payment_status == "WAIT_PLUG") {
                        this.message = `Pagamento feito com sucesso. <br><br>Conecte o carro...`;
                        this.waitPlug = true;
                        this.retryCheck();
                    } else if (result.payment_status == "ERROR") {
                        this.message = "Erro ao buscar informações do pagamento.... <br><br>Aguarde...";
                        this.retryCheck();
                    } else {
                        if (this.waitPlug == true) {
                            this.color = "warning";
                            this.message = `O valor do pagamento será estornado`;
                        }
                        this.counterError++;
                        if (this.counterError > 3) {
                            this.goToDetails();
                        } else {
                            this.retryCheck();
                        }
                    }

                    this.progress = result.progress;
                    this.timeout = result.timeout;

                }).catch(() => {
                    this.counterError++;
                    if (this.counterError > 3) {
                        this.goToDetails();
                    } else {
                        this.message = "Erro ao buscar informações do pagamento... <br><br>Aguarde...";
                        this.retryCheck();
                    }
                });
        },
    }
}
</script>